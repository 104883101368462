(function ($) {
  $(document).ready(function () {

    // header menu
    $('.mobile-toggler').on('click', function(e){
      e.preventDefault();
      $(this).toggleClass('open');
      $('.header').toggleClass('menu-open');
      $('body').toggleClass('menu-opened');
      $('.menu-header-mobile-menu-container').toggleClass('open');
    });

    // Full-Width w/ Text Overlay popup open
    $('.fullwidth-w-text-overlay__content--video-open').click(function (e){
      e.preventDefault();
      let videoID = $(this).attr('data-id');
      $('.fullwidth-w-text-overlay--video[data-id="'+videoID+'"]').addClass('is-active');
    });

    // Full-Width w/ Text Overlay popup close
    $('.fullwidth-w-text-overlay--video .close').click(function (e){
      e.preventDefault();
      let videoID = $(this).attr('data-id');
      $('.fullwidth-w-text-overlay--video[data-id="'+videoID+'"]').removeClass('is-active');
      $('.fullwidth-w-text-overlay--video[data-id="'+videoID+'"] .video').trigger('pause');
      let src = $('.fullwidth-w-text-overlay--video[data-id="'+videoID+'"] iframe').attr('src');
      $('.fullwidth-w-text-overlay--video[data-id="'+videoID+'"] iframe').attr('src', src);
    });


    // Four-Column carousel slider init
    $('.four-column-carousel__items').each( function() {
      let slider = $(this);
      slider.owlCarousel({
        loop: false,
        margin: 91,
        autoplay: false,
        nav: true,
        items: 4,
        responsiveClass: true,
        responsive:{
          0:{
            items:1,
            margin: 0,
          },
          768:{
            items:2,
            margin: 45,
          },
          1024:{
            items:4,
            margin: 91,
          }
        }
      });

    });

    // Carousel sliders
    $('.block-carousel__content--wrap').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      fade: false,
      loop: false,
      asNavFor: '.block-carousel__images'
    });
    $('.block-carousel__images').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      fade: true,
      loop: false,
      asNavFor: '.block-carousel__content--wrap'
    });


    // FAQ scripts
    $('.faq-redesign__items--aside--item').click(function(e){
      e.preventDefault();
      const itemID = $(this).attr('data-item');
      $('.faq-redesign__items--aside--item').removeClass('is-active');
      $('.faq-redesign__items--content--item').removeClass('is-active');
      $(this).addClass('is-active');
      $('.faq-redesign__items--content--item[data-item="'+itemID+'"]').addClass('is-active');
    });

    $('.faq-redesign__items--content--item--faq-it--title').click(function(e){
      const parent = $(this).parent('.faq-redesign__items--content--item--faq-it'),
            wrap = parent.parent('.faq-redesign__items--content--item');
      wrap.find('.faq-redesign__items--content--item--faq-it').removeClass('is-active');
      parent.addClass('is-active');
    });


    // Load more careers
    $('.load-more-career-button').click(function (e){
      e.preventDefault();
      let loadMore = $(this),
          page = loadMore.attr('data-page'),
          max = loadMore.attr('data-max');

      $.ajax({
        url: tacoVars.ajaxurl,
        type: 'POST',
        data: {
          'action' : 'taco_load_more_careers',
          'page': page
        },
        beforeSend:function(xhr){
          loadMore.addClass('is-active');
        },
        success:function(data){
          let next_page = parseInt(page) + 1;
          if( next_page >= max ){
            loadMore.remove();
          }
          if( data ){
            $('.open-roles__wrap').append(data);
            loadMore.attr('data-page', next_page);
            loadMore.removeClass('is-active');
          }else{
            loadMore.remove();
          }
        }
      });
    });

    // validate email address
    function validateEmailAddress( emailAddress ) {
			var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
			return pattern.test(emailAddress);
    }

    // Add body class dependth of browser
    $.each($.browser, function (i) {
      $('body').addClass(i);
      return false;
    });

    // Add body class dependth of OS
    const os = ['iphone', 'ipad', 'windows', 'mac', 'linux'],
        match = navigator.appVersion.toLowerCase().match(new RegExp(os.join('|')));
    if (match) {
      $('body').addClass(match[0]);
    };

    // matchHeight init
    $('.same-height').matchHeight();
    $('.matchHeight').matchHeight();

    // Selectpicker init
    $('.form-select').selectpicker();

    // Selectpicker init on parent (for contact form 7)
    $('.form-select-wrap select').selectpicker();

    // Tel inout mask
    $('input[type="tel"]').mask('(000) 000-0000');

    // Datepicker init
    $('.form-date').datepicker({
      autoclose: true
    });

    var $navbarCollapse = $('.navbar-main-collapse');
    var $navbarBackdrop = $('.navbar-main-backdrop');

    $navbarCollapse
      .on('shown.bs.collapse', function () {
        $navbarBackdrop.show();
      })
      .on('hide.bs.collapse', function () {
        $navbarBackdrop.hide();
      });

    $navbarBackdrop.on('click', function () {
      $navbarCollapse.collapse('hide');
    });

    // OwlCarousel init
    $('.carousel').each( function() {

      var $carousel = $(this);

      if( $carousel.find('.item').length > 1 ) {
        $carousel.owlCarousel({
          loop: true,
          margin: 0,
          autoplay: true,
          nav: true,
          items: 1,
          autoplayTimeout: 5000,
          autoplayHoverPause: true
        });
      }

    });

    // Init custom bootstrap number input
    $('.form-number').bootstrapNumber({
      center: true
    });

    // Enable number input when choose protein
    $('input[name="catering_taco_bar_type"]').on('change', function () {
      $('.taco-bar-guests-wrap').show();
    });

    // Fix textarea with hidden input
    $('textarea[name="uniform_name_tag_custom"]').on('keyup', function () {
        if ($(this).val().length === 0) {
            $('input[name="uniform_name_tag_custom_hidden"]').val("0");
        } else {
          $('input[name="uniform_name_tag_custom_hidden"]').val($(this).val())
        }
    });

    // Check modal function
    function checkModalFunc($modal) {
      var $result = $('form.wpcf7-form').serializeArray();
      $modal.find('.item-review-wrap').css('display', 'inline-block');
      $modal.find('tr').show();

      // Fill table values from serialized array
      for (var i = 0, len = $result.length; i < len; i++) {
        var $title = $modal.find('.item-review-title[data-name="'+ $result[i].name +'"]');
        var $cell = $modal.find('.item-review-cell[data-name="'+ $result[i].name +'"]');

        // Set val Qty
        $title.html($result[i].value);
        $cell.html($result[i].value);

        // Hide tr if empty
        if($result[i].value === '0' || $result[i].value.length === 0) {
          $cell.closest('tr').hide();
        }
      }

      // Global hide variable for all tables
      var $globalHidden = true;

      // Check table
      $modal.find('table').each(function () {
        var $hidden = true;

        // Check tr
        $(this).find('tr').each(function () {

          // Hide tr if empty
          if($(this).css('display') !== 'none') {
            $hidden = false
          }
        });

        // Hide table if empty
        ($hidden) ? $(this).closest('.item-review-wrap').hide() : $globalHidden = false;
      });

      // Hide table if all tables empty
      var $textResult = $modal.find('.item-review-result');
      ($globalHidden) ? $textResult.html('No products added') : $textResult.html('');
    }

    // Contact Form 7 scroll to invalid field
    $('.wpcf7-form').on( 'click', '.wpcf7-submit', function(){
      $('.modal').modal('hide');
      if($(this).hasClass('invalid')) {
        $('html, body').animate({
          scrollTop: $(".wpcf7-not-valid").first().offset().top - 30
        }, 600);
      }
    });

    // Uniform check modal

    var orderData = [];

    function checkUniformModal( $modal ) {

      orderData = [];

      // prepare order data
      $('form.uniform-form .uniform-product').each( function() {

        var $product = $(this),
        productGroup = $product.parents('.uniform-group').data('title'),
        productTitle = $product.data('title'),
        productType = $product.data('type'),
        data = [];

        if( productType == 'name_tags' ) {

          $product.find('.name-tags-controls').each( function() {

            var $controls = $(this),
            $nameField = $controls.find('.name'),
            $posField = $controls.find('.position'),
            name = $nameField.val(),
            position = $posField.val(),
            val = position != '' ? name + ': ' + position : name;

            if( name != '' ) {
              data.push({
                title: productTitle,
                value: val
              });
            }

          });

        } else {

          $product.find('input').each( function() {
            var $input = $(this),
            //name = $input.attr('name'),
            value = $input.val(),
            title = $input.data('title');
  
            if( Number( value ) > 0 ) {
              data.push({
                title: title,
                //name: name,
                value: value
              });
            }
  
          });

        }

        if( data.length > 0 ) {

          orderData.push({
            productTitle: productTitle,
            productGroup: productGroup,
            productType: productType,
            data: data
          });

        }

      });

      $modal.find('.masonry-grid').html('');

      // build modal confirmation
      if( orderData.length > 0 ) {

        $modal.find('.item-review-result').addClass('hidden');

        var html = '';

        $( orderData ).each( function( i, item) {

          html += '<div class="col-md-4 item-review-wrap masonry-column">';
          html += '<div class="mb-3">';
          html += '<p class="mb-1"><b>' + item.productGroup.toUpperCase() + '</b></p>';
          html += '<p>' + item.productTitle + '</p>';
          html += '<table class="table table-bordered small mb-0">';

          $( item.data ).each( function( j, dataItem) {
            html += '<tr><th>' + dataItem.title + '</th><td class="item-review-cell">' + dataItem.value + '</td></tr>';
          });

          html += '</table></div></div>';
        });

        $modal.find('.masonry-grid').html( html );

      } else {
        $modal.find('.item-review-result').removeClass('hidden');
      }

    }

    // Uniforms check modal
    $('#uniform_check_modal').on('show.bs.modal', function () {
      checkUniformModal($(this));
    });

    // Uniforms form on submit
    $('#uniforms-submit').on( 'click', function( e ){
      e.preventDefault();

      var $form = $(this).parents('form'),
      $successMsg = $form.find('.alert-success'),
      formData = {},
      isFormInvalid = false;

      $successMsg.hide();
      $('.modal').modal('hide');

      $form.find('.wpcf7-not-valid-tip').remove();

      // validate form fields
      $form.find('input.cf-field, select.cf-field, textarea.cf-field').each( function() {
        var $field = $(this),
        $formGroup = $field.parents('.form-group'),
        $selectWrapper = $field.parents('.bootstrap-select'),
        name = $field.attr('name'),
        value = $field.val();

        $selectWrapper.removeClass('wpcf7-not-valid');
        $field.removeClass('wpcf7-not-valid');

        if( $field.hasClass('required') && $.trim( value ) == '' ) {

          $field.addClass('wpcf7-not-valid');
          $formGroup.append( $('<span class="wpcf7-not-valid-tip">The field is required.</span>') );
          isFormInvalid = true;

          if( $selectWrapper.length ) {
            $selectWrapper.addClass('wpcf7-not-valid');
          }

        } else if( $field.hasClass('email') && ! validateEmailAddress( $.trim( value ) ) ) {

          $field.addClass('wpcf7-not-valid');
          $formGroup.append( $('<span class="wpcf7-not-valid-tip">Please type a valid email address.</span>') );
          isFormInvalid = true;

        }

        formData[ name ] = value;

      });

      if( isFormInvalid ) {

        $('html, body').animate({
          scrollTop: $(".wpcf7-not-valid").first().offset().top - 30
        }, 600);

      } else {

        $form.find('input.cf-field, select.cf-field, textarea.cf-field').val('');
        $successMsg.fadeIn('fast');

        // submit form
        $.ajax({
          url: tacoVars.ajaxurl,
          type: 'POST',
          data: {
            'action' : 'taco_uniforms',
            'nonce' : tacoVars.ajaxNonce,
            'orderData': orderData,
            'formData': formData
          }
        });

      }

    });

    // Uniform add / remove employees for Name Tags
    $( document ).on( 'click', '.name-tags-controls .btn', function( e ) {
      e.preventDefault();

      var $btn = $(this),
      $group = $btn.parents('.name-tags-row');

      if( $btn.hasClass('btn-plus') ) {

        var $cloned = $group.clone();
        $cloned.find('input').val('');
        $cloned.insertAfter( $group );

        if( $('.name-tags-row').length > 1 ) {
          $('.name-tags-row').find('.btn-minus').removeClass('hidden');
        }

      } else {

        if( $('.name-tags-row').length == 2 ) {
          $group.remove();
          $('.name-tags-row').find('.btn-minus').addClass('hidden');
          return true;
        } 
        
        $group.remove();

      }


    });

    // Catering check modal
    $('#catering_check_modal').on('show.bs.modal', function () {
      checkModalFunc($(this));
    });

    // Check mobile OS
    var isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    // Mobile catering horizontal navigation
    var $navCatering = $('.nav-custom-mobile-anchors-wrap');
    if($navCatering.length > 0) {
      $navCatering.affix({
        offset: {
          top: $navCatering.offset().top,
        }
      });
      $('body').scrollspy({ target: '.nav-custom-mobile-anchors-wrap' });

      if(isMobile.any()) {
        $('.nav-custom-mobile-anchors-wrap').on('activate.bs.scrollspy', function () {
          $('.nav-custom-mobile-anchors').animate({
            scrollLeft: 0
          }, 0);
          $('.nav-custom-mobile-anchors').animate({
            scrollLeft: $('.nav-custom-mobile-anchors li.active').offset().left
          }, 0);
        })
      }
    }

    // Catering navigation smooth scroll
    $('.nav-custom-mobile-anchors a').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
      }, 500);
    });

    $('.nav-custom-mobile a').each(function () {
      var $href = $(this).attr('href') + '#menu_wrap';
      $(this).attr('href', $href);
    });

    $('.nav-custom-mobile-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      if(isMobile.any()) {
        var $customTabsNav = $('.nav-custom-mobile-tabs');
        var $currentItem = $(e.target).closest('li');
        var $customTabsNavScrolled = $customTabsNav.scrollLeft();
        $customTabsNav.animate({
          scrollLeft: $currentItem.offset().left + $customTabsNavScrolled - 15
        }, 800);
      }
    });

    // Uniform login basic validation
    $("#uniform_login").validate();

    // Uniform control next/last
    $('.uniform-control').on('shown.bs.tab', function (e) {
      var $currentHref = $(e.target).attr('href');
      var $uniformNav = $('.uniform-nav');
      var $currentLi= $('.uniform-nav a[href="'+ $currentHref +'"]').closest('li');
      $("html, body").animate({ scrollTop: 0 }, 300);
      $('.uniform-nav li').removeClass('active');
      $currentLi.addClass('active');

      if(isMobile.any()) {
        $uniformNav.animate({
          scrollLeft: 0
        }, 0);
        $uniformNav.animate({
          scrollLeft: $currentLi.offset().left
        }, 300);
      }
    });

    // Media query based on mobile os (iOS)
    if( isMobile.iOS() ) {
      $('.hide-ios').hide();
      $('.visible-ios').show();
    }

    // Media query based on mobile os (Android)
    if( isMobile.Android() ) {
      $('.hide-android').hide();
      $('.visible-android').show();
    }

    // Media query based on mobile os (Windows)
    if( isMobile.Windows() ) {
      $('.visible-windows').show();
    }

    $('#bh-sl-maxdistance').on('change', function () {
      $(this).closest('form').trigger('submit');
    });

    // Horizontal scroll nav for mobile
    if(isMobile.any()) {
      var $customNav = $('.nav-custom-mobile');
      var $selector;
      if($('.nav-custom-mobile li.active').length > 0) {
        $selector = $('.nav-custom-mobile li.active:not(:nth-child(1))');
      }
      else if($('.nav-custom-mobile li.current-menu-ancestor').length > 0) {
        $selector = $('.nav-custom-mobile li.current-menu-ancestor:not(:nth-child(1))');
      }
      else {
        return;
      }
      $customNav.animate({
        scrollLeft: $selector.offset().left
      }, 800);
    }

    // Object fit initialize
    ($('img.img-sq').length > 0) ? objectFitImages('img.img-sq') : null;
    ($('img.img-sq-fixed').length > 0) ? objectFitImages('img.img-sq-fixed') : null;

    // Sorry:) need not to customize locator plugin
    alert = function() {};

    // Customize contact form 7 alerts

    document.addEventListener( 'wpcf7invalid', function( event ) {
      $('.wpcf7-response-output').addClass('alert alert-danger');
    }, false );
    document.addEventListener( 'wpcf7spam', function( event ) {
      $('.wpcf7-response-output').addClass('alert alert-warning');
    }, false );
    document.addEventListener( 'wpcf7mailfailed', function( event ) {
      $('.wpcf7-response-output').addClass('alert alert-warning');
    }, false );
    document.addEventListener( 'wpcf7mailsent', function( event ) {
      $('.wpcf7-response-output').addClass('alert alert-success');
    }, false );

    // GTM for order ahead button on click action
    $('.order-ahead-flt a').on('click', function () {
      var axel = Math.random() + '';
      var a = axel * 10000000000000;
      $(this).append('<iframe src="https://9507264.fls.doubleclick.net/activityi;src=9507264;type=order0;cat=2019-004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
    });
  });
})( jQuery );
